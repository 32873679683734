@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.flex-imp {
  display: flex !important;
}

.slick-slide > div {
  margin: 15px;
}

@media screen and (max-width: 768px) {
  .slick-slide > div {
    margin: 15px 15px 15px 15px;
  }
}

@media screen and (min-width: 1550px) {
  .cus-grid-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .cus-mx {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
}

.cus-h {
  height: 400px;
}

@media screen and (max-width: 1024px) {
  .cus-h {
    height: auto;
    aspect-ratio: 1;
  } 
}

.w-26 {
  width: 7rem;
}

.mw-200 {
  min-width: 200px;
}

.h-390 {
  height: 390px;
}

.disabled {
  cursor: not-allowed;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #fff !important;
}

.d-block {
  display: block !important;
}

header .lazy-load-image-background.blur.lazy-load-image-loaded {
  display: block !important;
}

.minting-app {
  min-height: 120vh;
}

@media screen and (max-width: 1440px) {
  .minting-app {
    min-height: 150vh;
  }
}

.text-heading {
  font-family: 'Oswald', sans-serif;
}

img.about-image{ 
  transform: scale(0.75)
}

.slick-prev:before,
.slick-next:before {
  color: #881798 !important;
}

/* @media screen and (min-width:1366px){
    .zion-transporter{
    background-position: 21vh 100% !important;
  }
} */
